import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import LastUpdate from "../components/last-update"
import LineChartHolder from "../components/linechart-combined"
import { useStaticQuery, graphql } from "gatsby"
import * as d3 from "d3"

const DataSourceReference = props => {
  return (
    <p
      id="data-source"
      dangerouslySetInnerHTML={{ __html: "Source: " + props.data }}
    />
  )
}

export default props => {
  const [completeData, setCompleteData] = useState([])
  const [provincesData, setProvincesData] = useState([])

  const gatsbyData = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          last_updated
          data_source
          faqs {
            question
            answer
          }
        }
      }
    }
  `)
  var provinces = [
    {
      abbr: "AB",
      province: "Alberta",
      cases: 0,
      deaths: 0,
      uid: "48",
      cx: 213.48,
      cy: 396,
    },
    {
      abbr: "SK",
      province: "Saskatchewan",
      cases: 0,
      deaths: 0,
      uid: "47",
      cx: 285,
      cy: 417,
    },
    {
      abbr: "MB",
      province: "Manitoba",
      cases: 0,
      deaths: 0,
      uid: "46",
      cx: 353,
      cy: 413,
    },
    {
      abbr: "NL",
      province: "Newfoundland",
      cases: 0,
      deaths: 0,
      uid: "10",
      cx: 628,
      cy: 354,
    },
    {
      abbr: "PE",
      province: "Prince Edward Island",
      cases: 0,
      deaths: 0,
      uid: "11",
      cx: 673,
      cy: 451,
    },
    {
      abbr: "NS",
      province: "Nova Scotia",
      cases: 0,
      deaths: 0,
      uid: "12",
      cx: 670,
      cy: 480,
    },
    {
      abbr: "NT",
      province: "Northwest Territories",
      cases: 0,
      deaths: 0,
      uid: "61",
      cx: 204,
      cy: 259,
    },
    {
      abbr: "NU",
      province: "Nunavut",
      cases: 0,
      deaths: 0,
      uid: "62",
      cx: 349,
      cy: 283,
    },
    {
      abbr: "ON",
      province: "Ontario",
      cases: 0,
      deaths: 0,
      uid: "35",
      cx: 442,
      cy: 449,
    },
    {
      abbr: "NB",
      province: "New Brunswick",
      cases: 0,
      deaths: 0,
      uid: "13",
      cx: 644,
      cy: 465,
    },
    {
      abbr: "YT",
      province: "Yukon",
      cases: 0,
      deaths: 0,
      uid: "60",
      cx: 113,
      cy: 244,
    },
    {
      abbr: "BC",
      province: "British Columbia",
      cases: 0,
      deaths: 0,
      uid: "59",
      cx: 134,
      cy: 375,
    },
    {
      abbr: "QC",
      province: "Quebec",
      cases: 0,
      deaths: 0,
      uid: "24",
      cx: 560,
      cy: 402,
    },
  ]

  useEffect(() => {
    d3.csv(
      "https://gauntlet-covid19.s3.ca-central-1.amazonaws.com/covid19.csv",
      (error, data) => {
        if (data) {
          provinces.forEach((province, index) => {
            const temp = data.filter((item, i) => item.pruid === province.uid)
            const newest = temp[temp.length - 1]
            province.cases = newest.numconf
            province.deaths = newest.numdeaths
          })

          setCompleteData(data)
          setProvincesData(provinces)
        }
      }
    )
  }, [])

  return (
    <Layout
      pageTitle={gatsbyData.site.siteMetadata.title}
      pageDescription={gatsbyData.site.siteMetadata.description}
    >
      <LastUpdate date={gatsbyData.site.siteMetadata.last_updated} />
      <DataSourceReference data={gatsbyData.site.siteMetadata.data_source} />
      <div className="container" id="barchart-container">
        <LineChartHolder data={completeData} another="hi" />
      </div>
    </Layout>
  )
}
