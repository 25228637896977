import React from "react"
import * as d3 from "d3"
import { numberWithCommas } from "../helpers/helper"

class LineChart extends React.Component {
  constructor(props) {
    super(props)
    this.svgRef = React.createRef()
    this.state = {
      provinces: [],
    }
  }

  createThePlot() {
    var margin = {
        top: 30,
        right: this.props.provinceId === "-1" ? 30 : 20,
        bottom: 60,
        left: 35,
      },
      width = this.props.provinceId === "-1" ? 300 : 710,
      height = this.props.provinceId === "-1" ? 250 : 350

    const width_nomargin = width - margin.left - margin.right
    const height_nomargin = height - margin.bottom - margin.top

    // Parse the date / time
    var parseDate = d3.time.format("%d-%m-%Y").parse

    // Set the ranges
    var x = d3.time.scale().range([0, width_nomargin])
    var y = d3.scale.linear().range([height_nomargin, 0])

    // Define the axes
    var monthFormat = d3.time.format("%d-%b")

    var xAxis = d3.svg
      .axis()
      .scale(x)
      .orient("bottom")
      .tickSize(3)
      .ticks(5)
      .tickFormat((d, i) => {
        return monthFormat(d)
      })

    var yAxis = d3.svg
      .axis()
      .scale(y)
      .orient("left")
      .ticks(5)

    // Adds the svg canvas
    var svg = d3
      .select(this.svgRef.current)
      .append("svg")
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("viewBox", `0 0 ${width} ${height}`)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")")

    this.info = this.props.data

    this.info.forEach((province, i) => {
      if (
        province.data[province.data.length - 1].numconf ===
        province.data[province.data.length - 2].numconf
      ) {
        province.data = province.data.slice(0, province.data.length - 1)
      }
    })

    this.info.forEach(function(d) {
      d.data.forEach((item, i) => {
        if (String(item.date).indexOf("GMT") === -1)
          item.date = parseDate(String(item.date))
        item.cases = +parseInt(item.numconf.replace(",", ""))
      })
    })

    // Scale the range of the data
    x.domain(
      d3.extent(
        this.info[0].data.length > this.info[1].data.length
          ? this.info[0].data
          : this.info[1].data,
        function(d) {
          return d.date
        }
      )
    )
    y.domain([0, d3.max(this.info, d => d3.max(d.data, item => item.cases))])

    const colors = [
      "#b12a39",
      "#084177",
      "#381460",
      "#5b8c5a",
      "#522d5b",
      "#d7385e",
      "#363062",
      "#204051",
      "#2b580c",
      "#116979",
      "#687466",
      "#7d5e2a",
      "#444444",
    ]

    var valueline = d3.svg
      .line()
      .x(function(d) {
        return x(d.date)
      })
      .y(function(d) {
        return y(d.cases)
      })

    const lines = svg
      .selectAll("lines")
      .data(this.info)
      .enter()
      .append("g")

    lines
      .append("path")
      .attr("class", "line")
      .style("stroke", (d, i) => colors[i])
      .attr("d", function(d) {
        return valueline(d.data)
      })

    lines
      .append("circle")
      .attr("r", "2")
      .attr("cx", (d, i) => x(d.data[d.data.length - 1].date))
      .attr("cy", (d, i) => y(d.data[d.data.length - 1].cases))
      .style("fill", (d, i) => colors[i])

    svg
      .append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + height_nomargin + ")")
      .call(xAxis)
      .selectAll("text")
      .attr("y", 0)
      .attr("x", 9)
      .attr("dy", ".35em")
      .attr("transform", "rotate(90)")
      .style("text-anchor", "start")

    svg
      .append("g")
      .attr("class", "y axis")
      .call(yAxis)

    const names = svg
      .selectAll("names")
      .data(this.info)
      .enter()
      .append("g")

    const abbr = province_name => {
      var abbr_arr = {
        Alberta: "AB",
        Saskatchewan: "SK",
        Manitoba: "MB",
        Newfoundland: "NL",
        "Prince Edward Island": "PE",
        "Nova Scotia": "NS",
        "Northwest Territories": "NT",
        Nunavut: "NU",
        Ontario: "ON",
        "New Brunswick": "NB",
        Yukon: "YT",
        "British Columbia": "BC",
        Quebec: "QC",
        Canada: "CA",
      }
      return abbr_arr[province_name]
    }

    if (this.props.provinceId === "-2") {
      names
        .append("rect")
        .attr("x", "20")
        .attr("y", (d, i) => i * 17 + 4)
        .attr("width", 7)
        .attr("height", 2)
        //.attr("width", "10")
        //.attr("height", "5")
        //.attr("class", "province-rect")
        .style("fill", (d, i) => colors[i])

      names
        .append("text")
        .attr("text-anchor", "start")
        .attr("class", "province-text-analysis")
        .attr(
          "transform",
          (d, i) => "translate(" + 32 + "," + (i * 17 + 8) + ")"
        )
        .text(
          (d, i) =>
            (d.data[0].prname.indexOf("Newfound") !== -1
              ? "Newfoundland"
              : d.data[0].prname) +
            " (" +
            numberWithCommas(d.data[d.data.length - 1].numconf) +
            ")"
        )
        .style("fill", (d, i) => colors[i])
    }

    if (this.props.provinceId === "-1") {
      names
        .append("text")
        .attr("text-anchor", "start")
        .attr(
          "transform",
          (d, i) =>
            "translate(" +
            (x(d.data[d.data.length - 1].date) + 4) +
            "," +
            y(parseInt(d.data[d.data.length - 1].numconf.replace(",", ""))) +
            ")"
        )
        .text((d, i) =>
          d.data[0].prname.indexOf("Newfound") !== -1
            ? "NL"
            : abbr(d.data[0].prname)
        )
        .style("fill", (d, i) => colors[i])

      svg
        .append("text")
        .attr("x", "0")
        .attr("text-anchor", "end")
        .attr("y", 12)
        .text("confirmed cases")
        .attr("transform", "rotate(270)")
    }
  }

  componentDidMount() {
    if (this.props.data.length > 0) {
      this.rendered = true
      this.createThePlot()
    }
  }

  componentDidUpdate() {
    if (this.props.data.length > 0 && !this.rendered) {
      this.rendered = true
      this.createThePlot()
    }
  }

  render() {
    const className = "column"
    return (
      <div ref={this.svgRef} id="svg-line-chart" className={className}></div>
    )
  }
}

class LineChartHolder extends React.Component {
  constructor(props) {
    super(props)
    this.counter = 5
    this.state = {
      provinces: [
        { code: "24", data: [] },
        { code: "35", data: [] },
        { code: "48", data: [] },
        { code: "59", data: [] },
        { code: "12", data: [] },
        { code: "47", data: [] },
        { code: "10", data: [] },
        { code: "46", data: [] },
        { code: "13", data: [] },
        { code: "11", data: [] },
        { code: "60", data: [] },
        { code: "61", data: [] },
        { code: "62", data: [] },
        { code: "1", data: [] },
      ],
      ab: [],
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.counter--
    if (this.state.provinces[0].data.length === 0 && this.counter > 0) {
      let provinces = []
      this.state.provinces.forEach((item, i) => {
        provinces[i] = {
          code: item.code,
          data: this.props.data.filter(d => d.pruid === item.code),
        }
      })
      const ab = provinces.filter((item, i) => item.code === "48")
      const ab_qb = ab.concat(provinces.filter((item, i) => item.code === "24"))
      const ab_bc = ab.concat(provinces.filter((item, i) => item.code === "59"))
      const ab_on = ab.concat(provinces.filter((item, i) => item.code === "35"))
      const ab_ns = ab.concat(provinces.filter((item, i) => item.code === "12"))
      const ab_sk = ab.concat(provinces.filter((item, i) => item.code === "47"))
      const ab_nf = ab.concat(provinces.filter((item, i) => item.code === "10"))
      const ab_mb = ab.concat(provinces.filter((item, i) => item.code === "46"))
      const ab_ca = ab.concat(provinces.filter((item, i) => item.code === "13"))

      this.setState({
        provinces,
        ab: [ab_qb, ab_on, ab_bc, ab_ns, ab_sk, ab_nf, ab_mb, ab_ca],
      })
    }
  }

  render() {
    const linecharts = this.state.ab.map((item, i) => (
      <LineChart provinceId="-1" data={item} key={i} />
    ))
    let whole = []
    for (let i = 0; i < linecharts.length; i = i + 2) {
      whole.push(
        <div key={i} className="row">
          {linecharts.slice(i, i + 2)}
        </div>
      )
    }

    return this.state.provinces[0].data.length === 0 ? null : (
      <>
        <div className="row">
          <LineChart
            data={this.state.provinces.slice(
              0,
              this.state.provinces.length - 1
            )}
            provinceId="-2"
          />
        </div>
        <hr />
        <h6 className="center-text">
          Confirmed Cases in Alberta Compared to Other Provinces
        </h6>
        <br />
        {whole}
      </>
    )
  }
}

export default LineChartHolder
